import { useRef, FC, useCallback, useEffect, useState, ReactNode, MouseEvent, useId } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { addClassToNode, removeClassFromNode } from '../Common/domNodeModifiers';
import { ModalProvider, ModalConsumer } from './modalContext';
import { ModalRefContext, ModalContextProps } from './refContext';
import { FocusTrap } from '../Common/FocusTrap';
import { useModalVisibilityHandler, useModalLayerManagerContext } from './ModalLayerManager';

const isTest = process.env.NODE_ENV === 'test';

import styles from './main.scss';

// DBL uses header, footer, and main elements for content
// modals are appended to the body and are siblings to the above
// elements, so they will not be made inert
// TODO update DAL to use the same semantic elements
const PAGE_CONTENT_SELECTORS = [
    'header',
    'footer',
    'main',
    '#back-to-top-button',
    // selectors for storybooks
    '.sb-wrapper ~ #root',
    '.sb-wrapper ~ #docs-root',
    '.sb-wrapper',
].join(', ');

function removeInert(): void {
    if (document) {
        Array.from(document.querySelectorAll(PAGE_CONTENT_SELECTORS)).forEach(node => {
            node.removeAttribute('inert');
        });
    }
}
function addInert(): void {
    if (document) {
        Array.from(document.querySelectorAll(PAGE_CONTENT_SELECTORS)).forEach(node => {
            node.setAttribute('inert', 'true');
        });
    }
}

export const modalSizes = {
    DEFAULT: 'default',
    MEDIUM: 'medium',
    LARGE: 'large',
    FULLSCREEN: 'fullscreen',
} as const;

const isDefaultModalType = (
    props: ModalContainerProps
): props is DefaultModalType & ModalContainerProps => !props.type || props.type === 'default';

const isSlideBottomModalType = (
    props: ModalContainerProps
): props is SlideBottomModalType & ModalContainerProps => props.type === 'slideBottom';

const isSlideRightModalType = (
    props: ModalContainerProps
): props is SlideRightModalType & ModalContainerProps => props.type === 'slideRight';

type BaseProps = {
    isOpen: boolean;
    onClose: () => void;
    appendToBody?: boolean;
    children?: ReactNode;
    closeOnEsc?: boolean;
    closeOnOutsideClick?: boolean;
    _INTERNAL_ONLY_CUSTOM_CONTENT_?: ReactNode;
    dataTn?: string;
    modalClass?: string;
    modalSize?: (typeof modalSizes)[keyof typeof modalSizes];
    onOpen?: () => void;
    overlayClass?: string;
    preventBackScroll?: boolean;
    removeModalOnClose?: boolean;
    ariaLabel?: string;
    ariaLabelledBy?: string;
    trapFocus?: boolean;
    renderWhileClosed?: boolean;
};
type DefaultModalType = {
    type?: 'default';
    animates?: boolean;
    modalPosition?: 'top' | 'center' | 'bottom';
    mobileModalSize?: 'fullHeight' | 'contentHeight';
};
type SlideBottomModalType = {
    type: 'slideBottom';
    mobileModalSize?: 'fullHeight' | 'contentHeight';
};
type SlideRightModalType = {
    type: 'slideRight';
};
export type ModalContainerProps = BaseProps &
    (DefaultModalType | SlideBottomModalType | SlideRightModalType);

export const ModalContainer: FC<ModalContainerProps> = props => {
    const {
        appendToBody = !isTest,
        isOpen,
        preventBackScroll = true,
        closeOnEsc = true,
        onOpen,
        removeModalOnClose = false,
        closeOnOutsideClick = true,
        onClose,
        children,
        _INTERNAL_ONLY_CUSTOM_CONTENT_,
        dataTn,
        modalClass,
        modalSize = modalSizes.DEFAULT,
        overlayClass,
        ariaLabel,
        ariaLabelledBy,
        trapFocus = true,
        renderWhileClosed = false,
    } = props;
    const animates = (isDefaultModalType(props) ? props.animates : true) ?? true;
    const modalManagerId = useId();

    const timeouts = useRef<NodeJS.Timeout[]>([]);
    const scrollY = useRef<number>(0);
    const portal = useRef<HTMLDivElement | null>(null);
    const trigger = useRef<HTMLElement | undefined>(undefined);
    const modalRef: ModalContextProps = useRef(null);
    const fallbackFocusRef = useRef<HTMLAnchorElement>(null);

    const [modalOpened, setModalOpened] = useState(false);
    const [modalHidden] = useModalVisibilityHandler({
        modalId: modalManagerId,
    });
    const { removeFromLayer, addToLayer } = useModalLayerManagerContext();
    const [animation, setAnimation] = useState<string | null>(null);

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Esc':
                case 'Escape': {
                    // prevent [ESC] keydown events on inputs from closing the modal
                    const isInputTarget =
                        e.target instanceof HTMLElement && e.target.tagName === 'INPUT';
                    if (closeOnEsc && !isInputTarget) {
                        onClose();
                    }
                    break;
                }
                default:
                    break;
            }
        },
        [closeOnEsc, onClose]
    );

    const lockScroll = useCallback(() => {
        scrollY.current = window.pageYOffset;
        addClassToNode({ domNode: document.body, className: styles.stopBodyScroll });
        // No need to scroll if position doesn't change - look at `.stopBodyScroll` styles
        if (scrollY.current === window.pageYOffset) {
            scrollY.current = 0;
        }
    }, [scrollY]);

    const unlockScroll = useCallback(() => {
        removeClassFromNode({ domNode: document.body, className: styles.stopBodyScroll });
        // Scroll back to previous position
        if (scrollY.current) {
            window.scrollTo(0, scrollY.current);
        }
    }, [scrollY]);

    const removeModal = useCallback(() => {
        if (appendToBody && document.body && portal.current) {
            removeFromLayer(modalManagerId);
            document.body.removeChild(portal.current);
            portal.current = null;
        }
    }, [appendToBody, removeFromLayer, modalManagerId]);

    const prepareContainer = useCallback(() => {
        if (!portal.current) {
            portal.current = document.createElement('div');
        }
        if (document.body) {
            document.body.appendChild(portal.current);
        }
    }, []);

    const openModal = useCallback(() => {
        if (appendToBody) {
            addToLayer(modalManagerId);
        }
        if (document.activeElement instanceof HTMLElement) {
            trigger.current = document.activeElement;
        }
        if (appendToBody) {
            prepareContainer();
            addInert();
        }

        if (preventBackScroll && !animates) {
            lockScroll();
        }

        if (onOpen) {
            onOpen();
        }

        setModalOpened(true);
    }, [
        trigger,
        addToLayer,
        modalManagerId,
        appendToBody,
        preventBackScroll,
        animates,
        lockScroll,
        onOpen,
        setModalOpened,
        prepareContainer,
    ]);

    const closeModal = useCallback(() => {
        removeInert();
        removeFromLayer(modalManagerId);
        if (closeOnEsc) {
            /*
                    using @ts-ignore otherwise getting the following error:
                    Argument of type '(e: KeyboardEvent<Element>) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject'.
                        Type '(e: KeyboardEvent<Element>) => void' is not assignable to type 'EventListener'.
                    Types of parameters 'e' and 'evt' are incompatible.
                        Type 'Event' is missing the following properties from type 'KeyboardEvent<Element>': altKey, charCode, ctrlKey, getModifierState, and 12 more.
                */
            // @ts-ignore
            document.removeEventListener('keydown', handleKeydown);
        }

        if (preventBackScroll) {
            unlockScroll();
        }

        if (animates && modalOpened) {
            setAnimation('closing');
        } else {
            setModalOpened(false);
            if (removeModalOnClose) {
                removeModal();
            }
        }
    }, [
        removeFromLayer,
        modalManagerId,
        closeOnEsc,
        animates,
        modalOpened,
        preventBackScroll,
        unlockScroll,
        removeModalOnClose,
        removeModal,
        handleKeydown,
        setModalOpened,
        setAnimation,
    ]);

    const handleAnimationEnd = useCallback(() => {
        if (modalOpened && animation === 'opening' && preventBackScroll) {
            lockScroll();
        }
    }, [modalOpened, animation, preventBackScroll, lockScroll]);

    const endCloseAnimation = useCallback(() => {
        setModalOpened(false);
        setAnimation(null);

        if (removeModalOnClose) {
            removeModal();
        }
    }, [removeModalOnClose, removeModal, setModalOpened, setAnimation]);

    const handleOutsideClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (closeOnOutsideClick) {
                onClose();
            }
        },
        [closeOnOutsideClick, onClose]
    );

    const handleInsideClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    const clearTimeouts = useCallback(() => {
        timeouts.current.forEach(clearTimeout);
    }, [timeouts]);

    useEffect(() => {
        return () => {
            clearTimeouts();
            removeModal();
            removeInert();

            if (preventBackScroll) {
                unlockScroll();
            }
        };
    }, [clearTimeouts, unlockScroll, preventBackScroll, removeModal]);

    useEffect(() => {
        if (isOpen && !modalOpened) {
            openModal();
        } else if (!isOpen && modalOpened) {
            closeModal();
        }
    }, [isOpen, modalOpened, openModal, closeModal]);

    useEffect(() => {
        if (modalOpened && closeOnEsc && !modalHidden) {
            /*
            using @ts-ignore otherwise getting the following error:
            Argument of type '(e: KeyboardEvent<Element>) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject'.
                Type '(e: KeyboardEvent<Element>) => void' is not assignable to type 'EventListener'.
            Types of parameters 'e' and 'evt' are incompatible.
                Type 'Event' is missing the following properties from type 'KeyboardEvent<Element>': altKey, charCode, ctrlKey, getModifierState, and 12 more.
        */
            // @ts-ignore
            document.addEventListener('keydown', handleKeydown);

            return () => {
                document.removeEventListener('keydown', handleKeydown);
            };
        }
        return () => {};
    }, [closeOnEsc, handleKeydown, modalHidden, modalOpened]);

    useEffect(() => {
        if (modalOpened && animates) {
            timeouts.current.push(setTimeout(() => setAnimation('opening'), 10));
        }
    }, [modalOpened, animates, timeouts, setAnimation]);

    useEffect(() => {
        if (animates && modalOpened && animation === 'closing') {
            timeouts.current.push(setTimeout(endCloseAnimation, 300));
        }
    }, [animation, timeouts, endCloseAnimation, animates, modalOpened]);

    const overlayClasses = classnames(
        styles.overlayDefault,
        {
            [styles.overlayFadeStart]: animates,
            [styles.overlayDefaultOpening]: animates && animation === 'opening',
            [styles.overlayDefaultClosing]: animates && animation === 'closing',
            [styles.modalHidden]: !modalOpened || modalHidden,
        },
        overlayClass
    );

    let modalClasses = '';
    if (!_INTERNAL_ONLY_CUSTOM_CONTENT_) {
        let modalTypeSpecificClasses: classnames.ArgumentArray = [];

        if (isDefaultModalType(props)) {
            modalTypeSpecificClasses = [
                {
                    [styles.modalPositionCenter]: props.modalPosition === 'center',
                    [styles.modalPositionBottom]: props.modalPosition === 'bottom',
                },
            ];
        } else if (isSlideBottomModalType(props)) {
            modalTypeSpecificClasses = [
                styles.slideBottom,
                styles.slideBottomHidden,
                { [styles.slideBottomVisible]: animation === 'opening' },
            ];
        } else if (isSlideRightModalType(props)) {
            modalTypeSpecificClasses = [
                styles.slideRight,
                styles.slideRightHidden,
                { [styles.slideRightVisible]: animation === 'opening' },
            ];
        }

        const mobileModalSize =
            ((isDefaultModalType(props) || isSlideBottomModalType(props)) &&
                props.mobileModalSize) ||
            'fullHeight';

        modalClasses = classnames(
            styles.modalDefault,
            {
                [styles.modalMedium]: modalSize === 'medium',
                [styles.modalLarge]: modalSize === 'large',
                [styles.modalFullscreen]: modalSize === 'fullscreen',
                [styles.mobileModalSizeContentHeight]: mobileModalSize === 'contentHeight',
            },
            ...modalTypeSpecificClasses,
            modalClass
        );
    }
    /**
     * allowing focus on an element that has interactive descendants (like the
     * modal container) can cause problems. instead, focus a heading or fallback
     * because these elements provide a reasonable focus point when opening a modal
     * and do not have usually interactive descendants. `style.outline` must be
     * disabled because `FocusTrap` uses programmatic focus (`node.focus()`), which
     * may add `:focus-visible` to the focus target.
     */
    const getFallbackFocus = useCallback(function () {
        if (modalRef.current) {
            const heading = modalRef.current.querySelector(
                '[data-heading], h2, h3'
            ) as HTMLElement | null;
            // this will always return null or a DOM Node that extends HTMLElement
            // casting + truthy check is cheaper than instanceof check
            if (heading) {
                heading.style.outline = 'none';
                heading.setAttribute('tabindex', '-1');
                return heading;
            }
        }
        return fallbackFocusRef.current;
    }, []);

    const wrapperDataTn = (dataTn || 'modal-container') + '-' + (modalOpened ? 'open' : 'closed');
    const modalSelector = `modal-container-${modalOpened ? 'open' : 'closed'}`;
    const modal = (
        <ModalProvider>
            <ModalConsumer>
                {({ labelId, modalId }) => (
                    <FocusTrap
                        options={{
                            autoFocusFirstTabbable: false,
                            fallbackFocus: getFallbackFocus,
                            returnFocusOnDeactivate: true,
                            clickOutsideDeactivates: true,
                            setReturnFocus: trapFocus ? trigger.current : undefined,
                        }}
                        active={trapFocus && modalOpened && animation !== 'closing' && !modalHidden}
                    >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                        <div
                            onClick={handleOutsideClick}
                            id={modalId + 'Overlay'}
                            aria-modal="true"
                            role="dialog"
                            data-tn={wrapperDataTn}
                            data-modal-selector={modalSelector}
                            className={overlayClasses}
                            onTransitionEnd={handleAnimationEnd}
                            aria-label={ariaLabel}
                            aria-labelledby={
                                (!ariaLabel && (ariaLabelledBy || labelId)) || undefined
                            }
                        >
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,  jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
                            <div
                                // data-dibs-modal is a stable attribute to select the modal.
                                // replaces id="Modal", which has been changed into a dynamic uid.
                                data-dibs-modal
                                id={modalId}
                                onClick={handleInsideClick}
                                className={modalClasses}
                                data-tn={dataTn}
                                ref={modalRef}
                            >
                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, react/forbid-elements */}
                                <a id={`${modalId}Anchor`} ref={fallbackFocusRef} tabIndex={-1} />
                                <ModalRefContext.Provider value={modalRef}>
                                    {_INTERNAL_ONLY_CUSTOM_CONTENT_ || children}
                                </ModalRefContext.Provider>
                            </div>
                        </div>
                    </FocusTrap>
                )}
            </ModalConsumer>
        </ModalProvider>
    );

    if (renderWhileClosed) {
        if (!portal.current) {
            prepareContainer();
        }
    }

    if (appendToBody && portal.current) {
        return createPortal(modal, portal.current);
    }

    return modalOpened ? modal : null;
};
